import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default class Page extends Component {
    render() {
        const { data } = this.props;


        let heroImageURL = "";
        if (data.wordpressPost.featured_media.localFile.publicURL !== null) {
            heroImageURL = "<div style=background-image:url('" + data.wordpressPost.featured_media.localFile.publicURL + "') class='heroImage'></div>";
        }


        return (
            <Layout>
                <SEO title={data.wordpressPost.title} />
                <section id="welcomeSection" className="section-description">
                    <div dangerouslySetInnerHTML={{ __html: heroImageURL }}></div>
                    <h1>{data.wordpressPost.title}</h1>
                </section>
                <div className="page-content" dangerouslySetInnerHTML={{ __html: data.wordpressPost.content }}></div>
            </Layout>
        )
    }
}

export const query = graphql`
    query PageQuery($slug: String){
        wordpressPost(slug: {eq: $slug}){
            title,
            slug,
            content,
            featured_media{
                localFile{
                    publicURL
                }
            }
        }
    }
`